import React,{useState,useEffect} from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import UserList from '../CreateUser/UserList'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase.config'

const Users = () => {
    const [userList, setUserList] = useState([])
   
    const fetchUsers = async () =>{
        const q = query(collection(db, "users"), where("service", "==", "logistics"));
        const querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
          setUserList((prev)=>[...prev,{
           id:doc.id,
           ...doc.data()
          }])
      })
    }
    useEffect(() => {
      fetchUsers();
    }, [])
    
  return (
    <div className='bg-gray-100 flex '>
  <Sidebar />
    <div className='h-[100vh] overflow-y-scroll flex flex-1 flex-col ' >
    <Topbar />
        
        <UserList userList={userList} />
    </div>
    </div>
  )
}

export default Users