import React, { useState, useEffect } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import Orders from '../components/Orders/Orders'
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import ChoosePartner from '../components/Orders/ChoosePartner'
import { useDispatch, useSelector } from 'react-redux'
import { generateToken } from '../actions/shippingActions'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import { Timestamp } from "@firebase/firestore";
import { pinCodeList } from '../data/pinCodeData'
const OrderList = () => {
  const [orderList, setOrderList] = useState([])
  const [filteredOrderList, setFilteredOrderList] = useState([])
  const [branchInfo, setBranchInfo] = useState({})
  const { isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  )
  const [delhiveryOrders, setDelhiveryOrders] = useState([])
  const [activeTab, setActiveTab] = useState("all")
  const [hub, setHub] = useState("")
  const [branchList, setBranchList] = useState([])
  const [partnerModal, setPartnerModal] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange;
  const [csvOrders, setCsvOrders] = useState([])
  const handleDateSelect = (ranges) => {
    console.log(ranges)
  }
  const [order, setOrder] = useState({})
  const [orderID, setOrderID] = useState("")
  const [orderActivity, setOrderActivity] = useState([])
  const { shippingToken } = useSelector(
    (state) => state.shippingToken
  );
  const dispatch = useDispatch()
  const handlePartnerModal = () => {
    setPartnerModal(!partnerModal)
  }
  const deleteOrder = async (id) => {
    await deleteDoc(doc(db, "logisticOrder", id))
    window.location.reload()
  }
  const getBoxes = (data) => {

    let totalBoxes = 0
    data?.dimensions?.map((d) => {
      totalBoxes = Number(totalBoxes) + Number(d?.count)

    })
    return totalBoxes
    console.log(totalBoxes)


  }

  const fetchAdminOrders = async () => {
    setOrderList([])
    setFilteredOrderList([])
    setCsvOrders([])
    if (activeTab === "delivered") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "delivered"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        });
      });
    }
    else if (activeTab === "in-transit") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "in-transit"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
    else if (activeTab === "pickedUp") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "pickedUp"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
    else if (activeTab === "new") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "new"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });

      });
    }
    else {
      const q = query(collection(db, "logisticOrder"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
  }
  const fetchOrders = async () => {
    setOrderList([])
    setFilteredOrderList([])
    if (activeTab === "delivered") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "delivered"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
    else if (activeTab === "in-transit") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "in-transit"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
    else if (activeTab === "pickedUp") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "pickedUp"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
    else if (activeTab === "new") {
      const q = query(collection(db, "logisticOrder"), where("orderStatus", "==", "new"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
    else {
      const q = query(collection(db, "logisticOrder"), where("uid", "==", users), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setFilteredOrderList((prev) => [...prev, {
          id: doc.id,
          ...doc.data()
        }])
        setCsvOrders((prev) => {
          const newOrder = {
            lr: data?.lrno,
            createdAt: data?.createdAt?.toDate().toLocaleDateString('en-GB') + data?.createdAt?.toDate().toLocaleTimeString(),
            client: data?.userName,
            forwardingNumber: data?.forwardingNumber,
            dilveryaddrress: data?.dropoff_location?.address,
            delivery: data?.dropoff_location?.companyName,
            originPincode: data?.pickup_location?.pinCode,
            destinationPincode: data?.dropoff_location?.zip,
            actualWeight: Number(data?.weight) / 1000,
            chargableWeight: Math.ceil(data?.totalChargeableWeight),
            boxes: getBoxes(data),
            status: data?.orderStatus
          };

          return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        });
      });
    }
  }
  const fetchBranch = async (city) => {
    const q = query(collection(db, "branchLogistics"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setBranchList((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])

    });
  }

  const fetchOrder = async (id) => {
    const docRef = doc(db, "logisticOrder", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data()
      setOrder(docSnap.data())

      pinCodeList.map((pin) => {

        if (Number(pin.Pin) === Number(data?.pickup_location?.pinCode)) {
          console.log(data?.pickup_location?.pinCode, pin.Pin)
          console.log("hii")
          setHub(pin.hub)
        }
      })



    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }
  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedList = [...orderList];
    updatedList = updatedList.filter((item) => {
      return item?.lrno?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredOrderList(updatedList);
  };


  const handleDeliveryShipping = () => {

    const date = new Date()

    setOrderActivity()
    dispatch(generateToken())
    fetchOrder(orderID)

    console.log(order?.pickup_location?.pickupName?.companyName)
    if (shippingToken?.jwt?.length !== 0 && Object.keys(order).length !== 0 && order?.pickup_location?.pickupName?.companyName !== undefined) {
      var data = JSON.stringify({
        "ident": "",
        "pickup_location": order?.pickup_location?.pickupName?.companyName,
        "dropoff_location": order?.dropoff_location,
        "return_address": {
          "address": order?.pickup_location?.address,
          "zip": order?.pickup_location?.pinCode,
          "name": order?.pickup_location?.pinCode,
          "city": order?.pickup_location?.city,
          "region": order?.pickup_location?.state,
          "phone": order?.pickup_location?.personName
        },
        "d_mode": "Prepaid",
        "amount": 0.0,
        "rov_insurance": true,
        "invoices": order?.invoices,
        "weight": Number(order?.weight),
        "suborders": order?.suborders,

        "dimensions": order?.dimensions,
        "consignee_gst_tin": order?.consignee_gst_tin,
        "seller_gst_tin": order?.seller_gst_tin,
        "cb": {},


      });
      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://btob.api.delhivery.com/v3/manifest',
        headers: {
          'Content-Type': 'application/json',

          'Authorization': 'Bearer ' + shippingToken?.jwt
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          setPartnerModal(false)
          toast.success('Order Shipped Succesfully')
          const repairRef = doc(db, "logisticOrder", orderID);

          // Set the "capital" field of the city 'DC'
          updateDoc(repairRef, {
            orderStatus: "pickedUp",
            job_id: response?.data?.job_id,
            orderActivity: [...orderActivity, {
              status: "Picked Up",
              remark: "Order has been Pickedup",
              location: "GRC Bhiwandi",
              time: date
            }]
          });
        }).catch((err) => {
          toast.error("Order Has an Error")
        })
    }
  }


  const fetchByDate = async () => {
    setOrderList([])
    setFilteredOrderList([])
    setCsvOrders([])
    const q = query(collection(db, "logisticOrder"), where("createdAt", ">=", Timestamp.fromDate(startDate)), where("createdAt", "<=", Timestamp.fromDate(endDate)), orderBy("createdAt", "desc"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      setOrderList((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])
      setFilteredOrderList((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])
      setCsvOrders((prev) => {
        const newOrder = {
          lr: data?.lrno,
          createdAt: data?.createdAt?.toDate().toDateString() + data?.createdAt?.toDate().toLocaleTimeString(),
          client: data?.userName,
          date: data?.createdAt?.toDate().toLocaleDateString('en-GB'),
          forwardingNumber: data?.forwardingNumber,
          dilveryaddrress: data?.dropoff_location?.address,
          delivery: data?.dropoff_location?.companyName,
          originPincode: data?.pickup_location?.pinCode,
          destinationPincode: data?.dropoff_location?.zip,
          actualWeight: Number(data?.weight) / 1000,
          chargableWeight: Math.ceil(data?.totalChargeableWeight),
          boxes: getBoxes(data),
          status: data?.orderStatus
        };

        return [...prev, newOrder].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      });
    });
  }
  const clearDate = () => {
    setDateRange([null, null])
    if (userProfile?.role === "admin") {
      fetchAdminOrders()
    }
    else {
      fetchOrders()
    }
  }
  const handleOwnShipping = () => {
    const date = new Date()
    fetchOrder(orderID)
    console.log(order)
    const repairRef = doc(db, "logisticOrder", orderID);
    console.log(hub)
    if (Object.keys(order).length !== 0) {
      console.log("hii")
      updateDoc(repairRef, {

        orderStatus: "pickedUp",
        forwardingNumber: order?.lrno,
        orderActivity: [...orderActivity, {
          status: "Picked Up",
          remark: "Order has been Pickedup",
          location: "GRC Bhiwandi",
          time: date
        }]
      }).then(res => {
        handlePartnerModal()
      }).catch(err => {
        console.log(err.message)
      })
    }

  }



  const orderChangeStatus = async () => {
    const q = query(collection(db, "logisticOrder"), where)
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data()




    });
  }

  useEffect(() => {
    if (userProfile?.role === "admin") {
      fetchAdminOrders()
      fetchBranch()
    }
    else {
      fetchOrders()
    }
    orderChangeStatus()

  }, [activeTab, userProfile])
  useEffect(() => {
    // const intervalCall = setInterval(() => {
    //   handleDeliveryStatus();
    // }, 3000);
    // return () => {
    //   // clean up
    //   clearInterval(intervalCall);
    // };


  }, [])



  return (
    <div className='bg-gray-100 flex ' >
      <Toaster />
      <ChoosePartner branchList={branchList} branchInfo={branchInfo} setBranchInfo={setBranchInfo} handleOwnShipping={handleOwnShipping} handleDeliveryShipping={handleDeliveryShipping} partnerModal={partnerModal} setPartnerModal={setPartnerModal} handlePartnerModal={handlePartnerModal} />
      <Sidebar />

      <div className='h-[100vh] overflow-y-scroll flex flex-1 flex-col' >
        <Topbar />
        <Orders csvOrders={csvOrders} clearDate={clearDate} fetchByDate={fetchByDate} dateRange={dateRange} setDateRange={setDateRange} startDate={startDate} endDate={endDate} deleteOrder={deleteOrder} filteredOrderList={filteredOrderList} filterBySearch={filterBySearch} setOrderID={setOrderID} handlePartnerModal={handlePartnerModal} activeTab={activeTab} setActiveTab={setActiveTab} orderList={orderList} />
      </div>
    </div>
  )
}

export default OrderList