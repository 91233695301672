import React from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import { Button } from '@material-tailwind/react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Home = () => {
  const {isAuthenticated,users,userProfile  } = useSelector(
    (state)=>state.user
  )
  return (
    <div className='bg-gray-100 flex '>
       <Sidebar />
    { userProfile?.role !== "Delivery Boy"   && <div className='h-[100vh] overflow-y-scroll flex flex-1 flex-col ' >
      <Topbar />
           
         <div className='col-span-4  flex-col  gap-6 flex items-center justify-center' >
            <h3 className='text-[1.5rem] font-[GilroyBold]'>Welcome to GRC Express and Logistics</h3>
        {userProfile?.role === "Delivery Boy" && <Link to="/create-order" >  <Button>Create New Order</Button></Link>}
        {userProfile?.role !== "Delivery Boy" && <Link to="/orders" >  <Button>Order List</Button></Link>}
        </div>
        </div>}
    { userProfile?.role === "Delivery Boy" &&   <div className='  flex-col mt-36 mx-4 gap-6 flex items-center justify-center' >
            <h3 className='lg:text-[1.5rem] text-[1.3rem] text-center font-[GilroyBold]'>Welcome to GRC Express and Logistics</h3>
        {userProfile?.role === "Delivery Boy" && <Link to="/createDelivery" >  <Button>Create New Order</Button></Link>}
        {userProfile?.role !== "Delivery Boy" && <Link to="/orders" >  <Button>Order List</Button></Link>}
        </div>}
    </div>
  )
}

export default Home