import { Button, Input, Option, Select } from '@material-tailwind/react'
import React from 'react'

const Dimensions = ({ step, setStep, dimension, setDimension, dimentionData, setDimentionData, subOrders, setSubOrders, dropLocation, setDropLocation, mode, setMode, amount, setAmount, weight, setWeight, consigneeGST, setConsigneeGST, sellerGST, setSellerGST, orderID, setOrderID, insuranceType, setInsuranceType, lrDetails, setLrDetails, lrNumber, setLrNumber, handleDimensionData, removeDimesion }) => {
    return (
        <div className='bg-white px-6 py-3 my-4 ' >
            <h3 className='font-[GilroyBold]' >Dimensions</h3>

            <div className='w-[800px]' >
                <div className='my-6  gap-6 flex items-center flex-wrap justify-start' >
                    <div  >
                        <Input value={dimentionData.name} onChange={(e) => setDimentionData({ ...dimentionData, name: e.target.value })} required={true} label='Description' />
                    </div>
                    <div  >
                        <Input type='number' value={dimentionData.qty} onChange={(e) => setDimentionData({ ...dimentionData, qty: e.target.value })} required={true} label='Qty' />
                    </div>
                    <div  >
                        <Input type='number' value={dimentionData.width} onChange={(e) => setDimentionData({ ...dimentionData, width: e.target.value })} required={true} label='Width(in cm)' />
                    </div>

                    <div  >
                        <Input type='number' value={dimentionData.height} onChange={(e) => setDimentionData({ ...dimentionData, height: e.target.value })} required={true} label='Height (in cm)' />
                    </div>
                    <div  >
                        <Input type='number' value={dimentionData.length} onChange={(e) => setDimentionData({ ...dimentionData, length: e.target.value })} required={true} label='Length(in cm)' />
                    </div>
                </div>
                <div>
                    <div className='flex items-center justify-center' >
                        <Button onClick={handleDimensionData} >Add More</Button>
                    </div>
                </div>
                <div className=' my-4' >
                    <div className='grid font-[GilroyMedium] bg-gray-200 px-4 py-3 grid-cols-6 grid-flow-col' >

                        <p>Qty</p>
                        <p>Width</p>
                        <p>Height</p>
                        <p>Length</p>

                        <p>Actions</p>
                    </div>
                    {
                        dimension?.map((item) => (
                            <div className='grid font-[GilroyMedium] bg-white my-0 px-4 py-3 grid-cols-6 grid-flow-col' >

                                <p>{item.count}</p>
                                <p>{item.width}</p>
                                <p>{item.height}</p>
                                <p>{item.length}</p>

                                <p onClick={() => removeDimesion(item.name)} className='font-[GilroyBold] cursor-pointer' >X</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Dimensions