import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../firebase.config';
import ReactToPrint from 'react-to-print';
import { Button } from '@material-tailwind/react';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import logo from '../../src/asset/grc-logo.png'

const ShippinglabelAdmin = () => {

	const { id } = useParams();
	const [orderDetail, setOrderDetail] = useState({})
	const [quantity, setQuantity] = useState("")
	// console.log(quantity)
	console.log(orderDetail)
	const fetchOrderDetail = () => {
		// console.log("hi")
		onSnapshot(doc(db, "logisticOrder", id), (doc) => {
			const data = doc.data()
			setOrderDetail(data)
			setQuantity(data.totalqty)
		});
	}

	useEffect(() => {
		fetchOrderDetail()
	}, [id])

	console.log(
		quantity,
		Array(quantity),
		Array(quantity).fill(0).map((_, i) => i),
	);
	let componentRef = useRef();

	return (
		<>
			<div className='my-4 mx-2'>
				<ReactToPrint
					trigger={() => <Button>Print this out!</Button>}
					content={() => componentRef}
				/>

				<div className='grid grid-cols-2 gap-12 p-12' ref={(el) => (componentRef = el)}>
					{Array.from({ length: quantity }, (_, i) => (
						<div
							key={i}
							className="w-full flex flex-col h-[400px] flex-wrap justify-center page-break"
							style={{ pageBreakAfter: (i + 1) % 4 === 0 ? 'always' : 'auto' }}
						>
							<div className="w-[100%] p-3 bg-white border border-gray-300 rounded-lg m-2" style={{ height: '350px' }}>
								<div className="flex justify-between">
									<img className='w-[100px]' src={logo} alt="Logo" />
								</div>
								<div className="mt-4 flex  justify-between items-start">
									<p className="font-bold"><span className="font-bold">LR No:</span> {orderDetail?.lrno}</p>
									<p className='font-bold'>Box no: <span className='font-[GilroyBold]'>{i + 1}/{quantity}</span></p>
								</div>
								<div className="mt-3 flex justify-center items-start gap-6">
									<div className='w-1/2'>
										<p className="font-bold text-[15px]">From Location:</p>
										<p className='text-[10px]'>
											{orderDetail?.pickup_location?.personName}<br />
											{orderDetail?.pickup_location?.address},<br />
											{orderDetail?.pickup_location?.city},<br />
											India
										</p>
										<p className='text-[10px]'>{orderDetail?.pickup_location?.phone}</p>
									</div>
									<div className='w-1/2'>
										<p className="font-bold text-[15px]">To Location:</p>
										<p className='text-[10px]'>
											{orderDetail?.dropoff_location?.consignee}<br />
											{orderDetail?.dropoff_location?.address},<br />
											{orderDetail?.dropoff_location?.zip},<br />
											{orderDetail?.dropoff_location?.region}<br />
											India
										</p>
										<p className='text-[10px]'>{orderDetail?.pickup_location?.phone}</p>
									</div>
								</div>
								<div className="flex justify-between items-center">
									<Barcode value={id} className="w-[100px]" />
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

		</>
	)
}

export default ShippinglabelAdmin
{/* <div key={i} style={{ breakAfter: "page" }} className='px-6 py-3 border-[1px] border-gray-600' >

							<div className='grid grid-cols-2 grid-flow-col justify-between' >
								<div>
									<h3 className='font-[GilroyBold]' >From Location,</h3>
									<p className='text-[0.6rem]'>{orderDetail?.pickup_location?.personName}</p>
									<p className='text-[0.6rem]'>{orderDetail?.pickup_location?.address},{orderDetail?.pickup_location?.city},India</p>
									<p className='text-[0.6rem]'>{orderDetail?.pickup_location?.phone}</p>
								</div>
								<div >
									<h3 className='font-[GilroyBold]' >To Location,</h3>
									<p className='text-[0.6rem]' >{orderDetail?.dropoff_location?.consignee} </p>
									<p className='text-[0.6rem]'>{orderDetail?.dropoff_location?.consignee},{orderDetail?.dropoff_location?.address},{orderDetail?.dropoff_location?.zip},{orderDetail?.dropoff_location?.region} India</p>
									<p className='text-[0.6rem]'>{orderDetail?.dropoff_location?.phone}</p>
								</div>
							</div>
							<div className='grid grid-cols-2 mt-3 grid-flow-col justify-between items-center' >
								<div>
									<p>Box no: <span className='font-[GilroyBold]' > {i + 1}/{quantity}</span></p>
								</div>
								<div className='flex items-center justify-center flex-col' >
									<Barcode value={id} className="w-[100px]" />

									<p className='text-center mt-1 font-[GilroyMedium] text-[0.8rem]' >{orderDetail?.lrno}</p>
								</div>
							</div>

						</div> */}