import React, { useState, useEffect } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import CreateOrderPage from '../components/CreateOrder/CreateOrderPage'
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db, storage } from '../firebase.config'
import { uploadBytesResumable, ref as storageRef, getDownloadURL } from 'firebase/storage'
import { pinCodeList } from "../data/pinCodeData"
import { locationPricing } from '../data/LocationPricing'
import ConfirmationModal from '../components/CreateOrder/ConfirmationModal'
import { useDispatch, useSelector } from 'react-redux'
import { saveOrder } from '../actions/orderAction'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
const CreateOrder = () => {
  // const [step, setStep] = useState(1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { error, loading } = useSelector(
    (state) => state.order
  )
  const { isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  )
  const [confirmModal, setConfirmModal] = useState(false)
  const [invoiceList, setInvoiceList] = useState([])
  const [warehouseList, setWarehouseList] = useState([])
  const [pickupLocation, setPickupLocation] = useState({})
  const [salesInsuranceCharge, setSalesInsuranceCharge] = useState(0)
  const [user, setUser] = useState({})
  const [dimension, setDimension] = useState([])
  const [lrDetails, setLrDetails] = useState("manual")
  const [lrNumber, setLrNumber] = useState("")
  const [orderID, setOrderID] = useState("")
  console.log(lrNumber)
  const [pickupZone, setPickupZone] = useState("")
  const [deliveryZone, setDeliveryZone] = useState("")
  const [baseAmount, setBaseAmount] = useState(0)
  const [userList, setUserList] = useState([])
  const [orderList, setOrderList] = useState([])
  const [totalbox, setTotalbox] = useState("")
  const [salesCharge, setSalesCharge] = useState(0)
  const [dimentionData, setDimentionData] = useState({
    name: "",
    qty: "",
    length: "",
    width: "",
    height: ""
  })
  const [volumetricWeight, setVolumetricWeight] = useState(0)
  const handleConfirmModal = () => {
    setConfirmModal(!confirmModal)
  }
  const [subOrders, setSubOrders] = useState([])
  const [subOrder, setSubOrder] = useState({
    ident: "",
    count: "",
    description: "",


  })
  const [dropLocation, setDropLocation] = useState({
    address: "",
    zip: "",
    consignee: "",
    city: "",
    region: "",
    phone: "",
    companyName: ""
  })
  const [mode, setMode] = useState("")
  const [invoiceDetail, setInvoiceDetail] = useState({
    ident: "",
    n_value: 0,
    ewaybill: ""
  })
  const [amount, setAmount] = useState(null)
  const [purchaseFuel, setPurchaseFuel] = useState(0)
  const [totalHeight, setTotalHeight] = useState(0)
  const [totalBoxQty, setTotalBoxQty] = useState(0)
  const [totalWidth, setTotalWidth] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [weight, setWeight] = useState(null)
  const [consigneeGST, setConsigneeGST] = useState("")
  const [salesTotalCharge, setSalesTotalCharge] = useState(0)
  const [sellerGST, setSellerGST] = useState("")
  const [invoiceDocument, setInvoiceDocument] = useState("")
  const [supportDocument, setSupportDocument] = useState("")
  const [totalChargeableWeight, setTotalChargeableWeight] = useState(0)
  const [productlist, setproductlist] = useState({})
  const [totalqty, settotalqty] = useState(0)
  const [totalwidth, setTotalwidth] = useState(0)
  const [totalheight, settotalheight] = useState(0)
  const [loadingOrder, setLoadingOrder] = useState(false)
  const handleDimensionData = () => {

    const totalQauntity = totalqty + Number(dimentionData.qty)
    settotalqty(totalQauntity)

    setTotalLength(totalLength + Number(dimentionData.length))
    settotalheight(totalheight + Number(dimentionData.height))
    setTotalWidth(totalwidth + Number(dimentionData.width))

    setDimension([...dimension, {
      length: Number(dimentionData.length),
      width: Number(dimentionData.width),
      height: Number(dimentionData.height),
      count: Number(dimentionData.qty)

    }])
    const chargableWeight = ((dimentionData.length * dimentionData.width * dimentionData.height) / 4500) * dimentionData.qty
    setTotalChargeableWeight(() => (totalChargeableWeight + Number(chargableWeight)))
    setSubOrders([
      ...subOrders, {
        ident: lrNumber + Number(dimension.length) + 1,
        count: Number(dimentionData.qty),
        description: dimentionData.name,
      }
    ])

    setDimentionData({
      name: "",
      qty: "",
      length: "",
      width: "",
      height: ""
    })
  }
  console.log(totalqty)
  const removeDimesion = (name) => {
    setDimension(
      dimension.filter((item) => item.name !== name)
    )
  }


  const handleShippingDetails = () => {


    // if (weight === null) {
    //   alert("Enter Total Weight")
    // }
    // else if (orderID.length === 0) {
    //   alert("Please enter Order ID")
    // }
    // else if (dimension.length === 0) {
    //   alert("Please enter Dimension")
    // }

    // else {
    //   const date = new Date()
    //   if (Object.keys(pickupLocation).length !== 0) {
    //     pinCodeList.map((zip) => {
    //       if (zip.Pin === Number(pickupLocation.pinCode)) {
    //         setPickupZone(zip.Zone)
    //       }
    //     })
    //   }
    //   if (dimension.length !== 0) {
    //     let Length = 0
    //     let Width = 0
    //     let Height = 0
    //     dimension.map((item) => {
    //       Length = Length + (Number(item.length) * item.count)
    //       Width = Width + (Number(item.width) * item.count)
    //       Height = Height + (Number(item.height) * item.count)
    //     })
    //     setTotalHeight(Height)
    //     setTotalWidth(Width)
    //     setTotalLength(Length)
    //   }
    //   if (orderList?.length !== 0 && lrDetails === "automatic") {
    //     setLrNumber(String(date.getFullYear()) + String((date.getMonth() <= 9 ? "0" + date.getMonth() : date.getMonth())) + String((date.getDate() <= 9 ? "0" + date.getDate() : date.getDate())) + String((Number(orderList.length) + 1)))
    //   }
    //   // setStep(2)
    // }
  }
  const addInvoice = (e) => {
    e.preventDefault()
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storeRef = storageRef(storage, `invoice/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {

      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInvoiceDocument(downloadURL)
          console.log(downloadURL)
        });
      }
    );
  }
  const addSupport = (e) => {
    e.preventDefault()
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storeRef = storageRef(storage, `invoice/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {

      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setSupportDocument(downloadURL)
          console.log(downloadURL)
        });
      }
    );
  }
  const handleDeliveryInformation = () => {
    // if (dropLocation.consignee.length === 0 || dropLocation.address.length === 0 || dropLocation.zip.length === 0 || dropLocation.region.length === 0 || dropLocation.city.length === 0 || dropLocation.phone.length === 0) {
    //   toast.error("Please enter All Delivery Details")
    // }
    // else {
    //   pinCodeList.map((zip) => {
    //     if (zip.Pin === Number(dropLocation.zip)) {
    //       setDeliveryZone(zip.Zone)
    //     }
    //   })
    //   // setStep(3)
    // }
  }
  const handleBookOrder = () => {
    // if (invoiceDetail?.collectionType === "COD" && invoiceDetail?.amountCollection?.length === 0) {
    //   toast.error("Please add Amount to Collect ")
    // }
    // else if (invoiceList?.length === 0) {
    //   toast.error("Please Add Invoice  ")
    // }
    // else {


    //   if (pickupZone.length !== 0 && deliveryZone.length !== 0) {
    //     handleConfirmModal()
    //     locationPricing.map((location) => {
    //       if (location.locationOne === pickupZone && location.locationTwo === deliveryZone) {
    //         setBaseAmount(location.charge)
    //       }
    //     })
    //     if (user?.rateList?.length !== 0) {
    //       user?.rateList?.map((item) => {
    //         if (item?.deliveryLocation === Number(dropLocation.zip) && item?.fromLocation === Number(pickupLocation.pinCode)) {
    //           setSalesCharge((((totalHeight * totalWidth * totalLength) / 4500) * item?.rate) + ((((totalHeight * totalWidth * totalLength) / 4500) * item?.rate) * (item?.fuel / 100)) + item?.lrCharge + item?.fmCost + item?.greenTax + item?.handling + item?.oda)
    //           if (user?.insuranceType === "owner risk") {
    //             setSalesInsuranceCharge(item?.rovOWER)
    //           }
    //           else {
    //             const rateCarrier = (((totalHeight * totalWidth * totalLength) / 4500) * item?.rate) * (item?.fuel / 100)
    //             if (rateCarrier > item?.minRovValue) {
    //               setSalesInsuranceCharge(Math.round(rateCarrier))
    //             }
    //             else {
    //               setSalesInsuranceCharge(item?.minRovValue)
    //             }
    //           }
    //         }
    //       })
    //     }

    //   }
    // }


  }
  console.log(totalChargeableWeight)
  const handleConfirmOrder = async () => {


    if (orderList?.length === 0) {
      console.log(orderList?.length)
      fetchOrders()
    }



    setVolumetricWeight((totalHeight * totalWidth * totalLength) / 4500)
    setAmount(((totalHeight * totalWidth * totalLength) / 4500) * baseAmount)
    setPurchaseFuel((((totalHeight * totalWidth * totalLength) / 4500) * baseAmount) * (20 / 100))
    setSalesTotalCharge(salesCharge + salesInsuranceCharge)
    const date = new Date()

    if (weight === null) {
      alert("Enter Total Weight")
    }
    else if (orderID.length === 0) {
      alert("Please enter Order ID")
    }
    else if (dimension.length === 0) {
      alert("Please enter Dimension")
    }

    else {
      const date = new Date()
      if (Object.keys(pickupLocation).length !== 0) {
        pinCodeList.map((zip) => {
          if (zip.Pin === Number(pickupLocation.pinCode)) {
            setPickupZone(zip.Zone)
          }
        })
      }
      if (dimension.length !== 0) {
        let Length = 0
        let Width = 0
        let Height = 0
        dimension.map((item) => {
          Length = Length + (Number(item.length) * item.count)
          Width = Width + (Number(item.width) * item.count)
          Height = Height + (Number(item.height) * item.count)
        })
        setTotalHeight(Height)
        setTotalWidth(Width)
        setTotalLength(Length)
      }
      // if (orderList?.length !== 0 && lrDetails === "automatic") {
      //   setLrNumber(String(date.getFullYear()) + String((date.getMonth() <= 9 ? "0" + date.getMonth() : date.getMonth())) + String((date.getDate() <= 9 ? "0" + date.getDate() : date.getDate())) + String((Number(orderList.length) + 1)))
      // }
      // setStep(2)
    }



    if (dropLocation.consignee.length === 0 || dropLocation.address.length === 0 || dropLocation.zip.length === 0 || dropLocation.region.length === 0 || dropLocation.city.length === 0 || dropLocation.phone.length === 0) {
      toast.error("Please enter All Delivery Details")
    }
    else {
      pinCodeList.map((zip) => {
        if (zip.Pin === Number(dropLocation.zip)) {
          setDeliveryZone(zip.Zone)
        }
      })
      // setStep(3)
    }


    if (invoiceDetail?.collectionType === "COD" && invoiceDetail?.amountCollection?.length === 0) {
      toast.error("Please add Amount to Collect ")
    }
    else if (invoiceList?.length === 0) {
      toast.error("Please Add Invoice  ")
    }
    else {


      if (pickupZone.length !== 0 && deliveryZone.length !== 0) {
        // handleConfirmModal()
        locationPricing.map((location) => {
          if (location.locationOne === pickupZone && location.locationTwo === deliveryZone) {
            setBaseAmount(location.charge)
          }
        })
        if (user?.rateList?.length !== 0) {
          user?.rateList?.map((item) => {
            if (item?.deliveryLocation === Number(dropLocation.zip) && item?.fromLocation === Number(pickupLocation.pinCode)) {
              setSalesCharge((((totalHeight * totalWidth * totalLength) / 4500) * item?.rate) + ((((totalHeight * totalWidth * totalLength) / 4500) * item?.rate) * (item?.fuel / 100)) + item?.lrCharge + item?.fmCost + item?.greenTax + item?.handling + item?.oda)
              if (user?.insuranceType === "owner risk") {
                setSalesInsuranceCharge(item?.rovOWER)
              }
              else {
                const rateCarrier = (((totalHeight * totalWidth * totalLength) / 4500) * item?.rate) * (item?.fuel / 100)
                if (rateCarrier > item?.minRovValue) {
                  setSalesInsuranceCharge(Math.round(rateCarrier))
                }
                else {
                  setSalesInsuranceCharge(item?.minRovValue)
                }
              }
            }
          })
        }

      }
    }



    if (amount !== null && orderList?.length !== 0) {


      dispatch(saveOrder({
        ident: "",
        createdAt: date,
        pickup_location: pickupLocation,
        dropoff_location: dropLocation,
        return_address: dropLocation,
        d_mode: "Prepaid",
        purchaseCharge: amount + Number(purchaseFuel),
        salesCharge: salesTotalCharge,
        orderStatus: "new",
        payment_mode: "Prepaid",
        rov_insurance: user?.insuranceType,
        invoices: invoiceList,
        weight: weight,
        suborders: subOrders,
        dimensions: dimension,
        consignee_gst_tin: consigneeGST,
        seller_gst_tin: sellerGST,
        cb: {},
        // totalbox: totalbox,
        totalqty: totalqty,
        orderID: orderID,
        lrno: (orderList?.length !== 0 && lrDetails === "automatic") ? String(date.getFullYear()) + String((date.getMonth() <= 9 ? "0" + date.getMonth() : date.getMonth())) + String((date.getDate() <= 9 ? "0" + date.getDate() : date.getDate())) + String((Number(orderList.length) + 1)) : lrNumber,
        totalChargeableWeight: totalChargeableWeight,
        masterAWB: "",
        uid: user?.uid,
        userName: user?.companyName,
        orderActivity: [{
          status: "Order Created",
          remark: "Order has been Created",
          location: "",
          time: date
        }]
      }, (orderList?.length !== 0 && lrDetails === "automatic") ? String(date.getFullYear()) + String((date.getMonth() <= 9 ? "0" + date.getMonth() : date.getMonth())) + String((date.getDate() <= 9 ? "0" + date.getDate() : date.getDate())) + String((Number(orderList.length) + 1)) : lrNumber))
        .then(
          setLoadingOrder(false)
        )
      if (!error) {
        const repairRef = doc(db, "users", user?.uid);
        updateDoc(repairRef, {
          billAmount: userProfile?.billAmount ? userProfile?.billAmount : 0 + salesTotalCharge,
        });
        navigate("/success")
      }
    }
  }



  const fetchWarehouse = async () => {


    const q = query(collection(db, "warehouse"), where("userUid", "==", user.uid))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setWarehouseList((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])

    });


  }
  console.log(user, warehouseList)
  const fetchUsers = async () => {
    const q = query(collection(db, "users"), where("service", "==", "logistics"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setUserList((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])

    });
  }
  console.log(pickupZone, deliveryZone, baseAmount, amount)
  console.log(totalHeight, totalLength, totalWidth, volumetricWeight)
  const fetchOrders = async () => {
    const q = query(collection(db, "logisticOrder"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setOrderList((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])


    });
  }
  console.log(lrNumber)
  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      fetchWarehouse()
    }
    // fetchOrders()
    fetchUsers()
  }, [user]);

  console.log(salesCharge)
  console.log(lrNumber)

  return (
    <>
      <div className='bg-gray-100 flex '>

        <Toaster />
        <Sidebar />
        <ConfirmationModal confirmModal={confirmModal} handleConfirmOrder={handleConfirmOrder} handleConfirmModal={handleConfirmModal} />
        <div className='h-[100vh] overflow-y-scroll flex flex-1 flex-col' >

          <Topbar />
          <CreateOrderPage
            loading={loading}
            totalbox={totalbox}
            setTotalbox={setTotalbox}
            invoiceList={invoiceList}
            setInvoiceList={setInvoiceList}
            userList={userList}
            setUserList={setUserList}
            user={user}
            setUser={setUser}
            handleBookOrder={handleBookOrder}
            handleConfirmOrder={handleConfirmOrder}
            handleDeliveryInformation={handleDeliveryInformation}
            addInvoice={addInvoice}
            addSupport={addSupport}
            supportDocument={supportDocument}
            invoiceDocument={invoiceDocument}
            invoiceDetail={invoiceDetail}
            setInvoiceDetail={setInvoiceDetail}
            warehouseList={warehouseList}
            pickupLocation={pickupLocation}
            setPickupLocation={setPickupLocation}
            handleShippingDetails={handleShippingDetails}
            removeDimesion={removeDimesion}
            handleDimensionData={handleDimensionData}
            dimentionData={dimentionData}
            setDimentionData={setDimentionData}
            setDimension={setDimension}
            dimension={dimension}
            subOrders={subOrders}
            setSubOrders={setSubOrders}
            dropLocation={dropLocation}
            setDropLocation={setDropLocation}
            mode={mode}
            setMode={setMode}
            amount={amount}
            setAmount={setAmount}
            weight={weight}
            setWeight={setWeight}
            consigneeGST={consigneeGST}
            setConsigneeGST={setConsigneeGST}
            sellerGST={sellerGST}
            setSellerGST={setSellerGST}

            lrNumber={lrNumber}
            setLrNumber={setLrNumber}
            orderID={orderID}
            orderList={orderList}
            setOrderID={setOrderID}
            lrDetails={lrDetails}
            setLrDetails={setLrDetails}
          // step={step} setStep={setStep} 

          />
        </div>
      </div>
    </>
  )
}

export default CreateOrder