import React, { useState } from 'react'
import { Sidebar } from '../components/Layout/Sidebar'
import Topbar from '../components/Layout/Topbar'
import UserForm from '../CreateUser/UserForm'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth, db, storage } from '../firebase.config'
import { doc, setDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { getDownloadURL, uploadBytesResumable, ref as storageRef } from 'firebase/storage'
import * as XLSX from 'xlsx';
const CreateUser = () => {
  const navigate = useNavigate("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [gstNo, setGstNo] = useState("")
  const [gstDocument, setGstDocument] = useState("")
  const [agreement, setAgreement] = useState("")
  const [otherDoc, setOtherDoc] = useState("")
  const [insuranceType, setInsuranceType] = useState("")
  const [contact, setContact] = useState("")
  const [companyAddress, setCompanyAddress] = useState("")
  const [rateChart, setRateChart] = useState(null)
  const [rateJson, setRateJson] = useState("")
  const handleCreateUser = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), {
          name: name,
          email: email,
          password: password,
          contact: contact,
          companyName: companyName,
          gstNo: gstNo,
          gstDocument: gstDocument,
          agreement: agreement,
          otherDoc: otherDoc,
          insuranceType: insuranceType,
          companyAddress: companyAddress,
          role: "client",
          partnerType: "postpaid",
          walletBalance: "",
          service: "logistics",
          uid: user.uid,
          rateList: rateJson
        })
        navigate("/users")
      }).catch((err) => {
        console.log(err)
      })
  }
  const addGSTInformation = (e) => {
    e.preventDefault()
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storeRef = storageRef(storage, `invoices/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {

      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setGstDocument(downloadURL)
          console.log(downloadURL)
        });
      }
    );
  }
  const addAgreementInformation = (e) => {
    e.preventDefault()
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storeRef = storageRef(storage, `invoices/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {

      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setAgreement(downloadURL)
          console.log(downloadURL)
        });
      }
    );
  }
  const handleConvert = () => {
    if (rateChart) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setRateJson(json);

      };

      reader.readAsBinaryString(rateChart);
    }
  }
  console.log(rateJson)
  return (
    <div className='bg-gray-100 flex '>
      <Sidebar />
      <div className='h-[100vh] overflow-y-scroll flex flex-1 flex-col' >
        <Topbar />

        <UserForm name={name} setName={setName}
          rateChart={rateChart} setRateChart={setRateChart}
          rateJson={rateJson} setRateJson={setRateJson}
          handleConvert={handleConvert}
          addAgreementInformation={addAgreementInformation}
          addGSTInformation={addGSTInformation}
          handleCreateUser={handleCreateUser}
          companyAddress={companyAddress} setCompanyAddress={setCompanyAddress}
          email={email} setEmail={setEmail}
          password={password} setPassword={setPassword}
          companyName={companyName} setCompanyName={setCompanyName}
          gstNo={gstNo} setGstNo={setGstNo}
          agreement={agreement} setAgreement={setAgreement}
          gstDocument={gstDocument} setGstDocument={setGstDocument}
          otherDoc={otherDoc} setOtherDoc={setOtherDoc}
          insuranceType={insuranceType} setInsuranceType={setInsuranceType}
          contact={contact} setContact={setContact}
        />
      </div>
    </div>
  )
}

export default CreateUser