import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { ChevronRightIcon, ChevronDownIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
 import { BsFillBoxSeamFill } from "react-icons/bs";
 import { RiAddBoxFill } from "react-icons/ri";
 import { LuTruck } from "react-icons/lu";
 import { HiOutlineLocationMarker } from "react-icons/hi"
 import { PiCalculatorBold } from "react-icons/pi";
 import { FaWarehouse,FaTicket } from "react-icons/fa6";
import { SiMinutemailer } from "react-icons/si";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { MdSwitchAccount } from "react-icons/md";
import { FaPen } from "react-icons/fa6";
import { AiOutlineException } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import GRCLogo from "../../asset/grc-logo.png"
export function Sidebar() {

  const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  const [open, setOpen] = React.useState(true); // Sidebar initially closed
  

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const dispatch = useDispatch()
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const handleLogout = () =>{
      dispatch(logout())
  }
  const activeClass = "bg-[#FFB200] hover:bg-[#FFB200] opacity-[100%] text-white rounded-[7px]";
  return (
    <Card className={`h-[100vh] hidden lg:flex flex-col lg:static   overflow-y-scroll overflow-x-visible  rounded-none shadow-xl shadow-blue-gray-900/5 ${open ? 'lg:w-[250px]' : 'w-16'
        }`}>
            <div>
            <img className='w-[120px] my-3 mx-4  ' src={GRCLogo} />
        </div>
      <h4 className="mt-1 font-[GilroyBold] mx-4 text-[1.3rem]" >My Orders</h4>
      <List>
      <Link className={splitLocation[1] === "orders" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/orders" >
        <ListItem className=" py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix className="hover:bg-[#FFB200] rounded-lg">
            <BsFillBoxSeamFill className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem] " >  Order List</p>
          
        </ListItem>
        </Link>
      {userProfile?.role==="admin" &&  <Link className={splitLocation[1] === "create-order" ? activeClass : "hover:bg-[#FFB200] rounded-lg"}  to="/create-order" >
       <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <RiAddBoxFill className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Create Order</p>
          
        </ListItem>
        </Link>}
        <Link className={splitLocation[1] === "request-pickup" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/request-pickup" >
          <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <LuTruck className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Request Pickup</p>
          
        </ListItem>
        </Link>
      </List>
       <h4 className="mt-4 font-[GilroyBold] ml-4 text-[1.3rem]" >Services</h4>
       <List className="mt-1" >
       <Link className={splitLocation[1] === "servicibility" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/servicibility">
        <ListItem className=" py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <HiOutlineLocationMarker className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" > Check Servicibility</p>
          
        </ListItem>
        </Link>
        <Link className={splitLocation[1] === "rate-calculator" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/rate-calculator">
       <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <PiCalculatorBold className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Rate Calculator</p>
          
        </ListItem>
        </Link>
        {userProfile?.role==="admin" &&  <Link className={splitLocation[1] === "create-invoice" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/create-invoice" >
       <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <RiAddBoxFill className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Create Invoice</p>
          
        </ListItem>
        </Link>}
        {userProfile?.role==="admin" &&  <Link className={splitLocation[1] === "warehouse" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/warehouse" >
         <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <FaWarehouse className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Manage Warehouse</p>
          
        </ListItem>
        </Link> }
        {userProfile?.role==="admin" &&  <Link className={splitLocation[1] === "createBranch" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/createBranch" >
         <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <FaWarehouse className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Create Branch</p>
          
        </ListItem>
        </Link> }
      </List>
       {/* <h4 className="mt-4 font-[GilroyBold] ml-4 text-[1.3rem]" >Help & Support</h4> */}
       {/* <List className="mt-1" >
        <ListItem className=" py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <FaTicket className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Raise a Ticket</p>
          
        </ListItem>
       <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <SiMinutemailer className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Contact Us</p>
          
        </ListItem>
         <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <BsFillQuestionCircleFill className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Faqs</p>
          
        </ListItem>
       
      </List> */}
  {userProfile?.role==="admin" &&  <>
      <h4 className="mt-4 font-[GilroyBold] ml-4 text-[1.3rem]" >User</h4>
       <List className="mt-1" >
       <Link className={splitLocation[1] === "create-user" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/create-user">
        <ListItem className=" py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <HiOutlineLocationMarker className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" > Create User</p>
          
        </ListItem>
        </Link>
        <Link className={splitLocation[1] === "create-staff" ? activeClass : "hover:bg-[#FFB200] rounded-lg"}  to="/create-staff">
        <ListItem className=" py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <HiOutlineLocationMarker className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" > Create Staff</p>
          
        </ListItem>
        </Link>
        <Link className={splitLocation[1] === "users" ? activeClass : "hover:bg-[#FFB200] rounded-lg"} to="/users">
       <ListItem className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <PiCalculatorBold className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >User List</p>
          
        </ListItem>
        </Link>
      
      </List>
        </>}

       <h4 className="mt-4 font-[GilroyBold] ml-4 text-[1.3rem]" >Profile</h4>
       <List className="mt-1 mb-6" >
        <ListItem className=" py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <MdSwitchAccount className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Account Details</p>
          
        </ListItem>
    <ListItem  onClick={handleLogout} className="py-3 hover:bg-[#FFB200] rounded-lg font-[GilroyMedium] hover:text-white" >
          <ListItemPrefix>
            <FaPen className="h-[15px] w-[15px] mr-3" />
          </ListItemPrefix>
        <p className="text-[.9rem]" >Logout</p>
          
        </ListItem>
 
       
      </List>
    </Card>
  );
}