import { TrashIcon } from '@heroicons/react/24/outline'
import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PaginatedItems from './PaginatedItems'

const OrderTable = ({ activeTab, orderList, handlePartnerModal, setOrderID, filteredOrderList, deleteOrder }) => {
  const { isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  )
  const [showAdd, setShowAdd] = useState('')
  return (
    <>
      <div className=''  >
        <div className={`bg-gray-300  py-4 px-8 grid  gap-6 grid-flow-row  items-center justify-start grid-cols-2 lg:grid-cols-8  `}>
          <p className='col-span-1 font-[GilroyMedium]' >Lr No</p>
          <p className='col-span-1 font-[GilroyMedium]' >Created Date</p>
          {/* <p className='col-span-1 font-[GilroyMedium]' >Order ID</p> */}
          <p className='col-span-1 font-[GilroyMedium]' >Client</p>
          <p className='col-span-1 font-[GilroyMedium]' >Consignee </p>
          <p className='col-span-1 font-[GilroyMedium]' >Origin</p>
          <p className='col-span-1 font-[GilroyMedium]' >Destination</p>

          <p className='col-span-1 font-[GilroyMedium]' >Order Status</p>
          <p className='col-span-1 font-[GilroyMedium]' >Action</p>
        </div>
        <PaginatedItems itemsPerPage={5} filteredOrderList={filteredOrderList} showAdd={showAdd} setShowAdd={setShowAdd} setOrderID={setOrderID} handlePartnerModal={handlePartnerModal} userProfile={userProfile}
          deleteOrder={deleteOrder} />

      </div>

    </>
  )
}

export default OrderTable