import React, { useEffect, useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import { Sidebar } from '../components/Layout/Sidebar'
import { useParams } from 'react-router-dom'
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import ViewOrderDetails from '../components/ViewOrder/ViewOrderDetails'
import { useDispatch, useSelector } from 'react-redux'
import { generateToken } from '../actions/shippingActions'
import axios from 'axios'
import UpdateStatusModal from '../components/ViewOrder/UpdateStatusModal'
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytesResumable,
  listAll,
} from "firebase/storage";
import { io } from "socket.io-client";
import { storage } from "../firebase.config";
const ViewOrder = () => {
  const { id } = useParams()
  const [orderDetail, setOrderDetail] = useState({})
  const [deliveryBoys, setDeliveryBoys] = useState([])
  const [deliveryBoy, setDeliveryBoy] = useState({})
  const [forwardingNumber, setForwardingNumber] = useState("")
  const [wayBill, setWayBill] = useState([])
  const [orderActivity, setOrderActivity] = useState([])
  const [updateModal, setUpdateModal] = useState(false)

  const [manualStatus, setManualStatus] = useState({
    status: "",
    remark: "",
    location: "",
  })
  const handleUpdateModal = () => {
    setUpdateModal(!updateModal)
  }
  const { shippingToken } = useSelector(
    (state) => state.shippingToken
  );
  const dispatch = useDispatch()
  const generateForwardingNumber = () => {
    dispatch(generateToken())
    if (shippingToken?.jwt?.length !== 0 && orderDetail?.job_id?.length !== 0) {
      const date = new Date()
      setOrderActivity([...orderActivity, {
        status: "In-Transit",
        remark: "Order has been Shipped to Partner",
        location: "GRC, Bhiwandi",
        time: date
      }])
      console.log(orderActivity)
      var config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://btob.api.delhivery.com/v3/manifest?job_id=' + orderDetail?.job_id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + shippingToken?.jwt
        },

      };
      axios(config)
        .then(function (response) {
          const repairRef = doc(db, "logisticOrder", id);
          updateDoc(repairRef, {
            orderStatus: "in-transit",
            forwardingNumber: response?.data?.status?.value?.lrnum,
            deliveryWaybill: response?.data?.status?.value?.waybills,
            deliveryMasterWaybill: response?.data?.status?.value?.master_waybill,
            orderActivity: [...orderActivity, {
              status: "In-Transit",
              remark: "Order has been Shipped to Partner",
              location: "GRC, Bhiwandi",
              time: date
            }]
          });


        })
        .catch(function (error) {
          alert("Error:" + error.message)

        })
    }

  }
  const handleManulOrder = () => {
    if (manualStatus.status === "") {
      alert("Choose a Status")
    }
    else if (manualStatus.remark === "") {
      alert("Enter Remark")
    }
    else if (manualStatus.location === "") {
      alert("Enter Location")
    }
    else {
      const date = new Date()
      const repairRef = doc(db, "logisticOrder", id);
      if (manualStatus.status === "delivering") {
        updateDoc(repairRef, {
          orderStatus: manualStatus.status,

          orderActivity: [...orderActivity, {
            status: manualStatus.status,
            remark: manualStatus.remark,
            location: manualStatus.location,
            time: date
          }],
          deliveryBoyDetails: deliveryBoy,
          deliveryBoyId: deliveryBoy?.id
        });
      }
      else {
        updateDoc(repairRef, {
          orderStatus: manualStatus.status,

          orderActivity: [...orderActivity, {
            status: manualStatus.status,
            remark: manualStatus.remark,
            location: manualStatus.location,
            time: date
          }],

        });
      }

    }
    handleUpdateModal()
  }
  const fetchDeliveryBoy = async () => {
    const q = query(collection(db, "users"), where("role", "==", "Delivery Boy"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setDeliveryBoys((prev) => [...prev, {
        id: doc.id,
        ...doc.data()
      }])

    });
  }
  const handleDeliveryStatus = () => {
    dispatch(generateToken())
    if (shippingToken?.jwt?.length !== 0 && orderDetail?.forwardingNumber?.length !== 0) {


      var config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://btob.api.delhivery.com/v3/track/' + orderDetail?.forwardingNumber,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + shippingToken?.jwt
        },

      };
      axios(config)
        .then(function (response) {
          const date = new Date()
          const data = response?.data?.data
          console.log(data)
          const repairRef = doc(db, "logisticOrder", id);
          console.log(data)
          if (data?.wbns[0]?.status === "DELIVERED") {
            updateDoc(repairRef, {
              orderStatus: "delivered",
              orderActivity: [...orderActivity, {
                status: data?.wbns[0]?.status,
                remark: data?.wbns[0]?.scan_remark,
                location: data?.wbns[0]?.location,
                time: date
              }]
            });
            var newconfig = {
              method: 'get',
              maxBodyLength: Infinity,

              url: 'https://btob.api.delhivery.com/v3/document/' + orderDetail?.forwardingNumber + "?doc_type=LM_POD",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + shippingToken?.jwt
              },

            };
            axios(newconfig).then(res => {
              console.log(res)
              updateDoc(repairRef, {

                pod: res?.data?.data[0]
              });
            })
          }
          else {
            updateDoc(repairRef, {
              orderActivity: [...orderActivity, {
                status: data?.wbns[0]?.status,
                remark: data?.wbns[0]?.scan_remark,
                location: data?.wbns[0]?.location,
                time: date
              }]
            });
          }


        })
        .catch(function (error) {
          alert("Error:" + error.message)

        })
    }
  }
  const addPOD = (e) => {
    e.preventDefault()
    const file = e.target[0]?.files[0]
    if (!file) return;
    const storeRef = storageRef(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {

      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const repairRef = doc(db, "logisticOrder", id);

          updateDoc(repairRef, {


            pod: downloadURL
          });
          console.log(downloadURL)
        });
      }
    );
  }
  console.log(orderDetail)
  const fetchOrderDetail = () => {

    onSnapshot(doc(db, "logisticOrder", id), (doc) => {
      const data = doc.data()
      setOrderDetail(data)
      setOrderActivity(data?.orderActivity)
    });
  }
  console.log(orderDetail)
  useEffect(() => {
    fetchOrderDetail()
    fetchDeliveryBoy()

  }, [id])
  const MINUTE_MS = 2000;

  useEffect(() => {



  }, [])

  return (
    <div className='bg-gray-100 flex '>
      <UpdateStatusModal deliveryBoy={deliveryBoy} setDeliveryBoy={setDeliveryBoy} deliveryBoys={deliveryBoys} setDeliveryBoys={setDeliveryBoys} handleDeliveryStatus={handleDeliveryStatus} updateModal={updateModal} setUpdateModal={setUpdateModal} handleUpdateModal={handleUpdateModal} manualStatus={manualStatus} setManualStatus={setManualStatus} handleManulOrder={handleManulOrder} />
      <Sidebar />
      <div className='h-[100vh] overflow-y-scroll flex flex-1 flex-col' >
        <Topbar />

        <ViewOrderDetails id={id} addPOD={addPOD} handleUpdateModal={handleUpdateModal} generateForwardingNumber={generateForwardingNumber} orderDetails={orderDetail} />
      </div>
    </div>
  )
}

export default ViewOrder